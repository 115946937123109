import React from "react";
import parse from "html-react-parser";
import { navigation } from "../../data/content";

const Header = (props) => {
  const { siteNameTitle, siteName, headerPhone, headerPhoneLink } = props;
  return (
    <header id="header" className="fixed-top">
      <div className="container d-flex justify-content-between">
        <div className="logo">
          <a href="#hero" title={siteNameTitle}>
            <h1>{parse(siteName)}</h1>
          </a>
        </div>

        <nav className="nav-menu d-none d-lg-block">
          <ul>
            {navigation.map((item, index) => {
              return (
                <li className={item.active ? "active" : null} key={index}>
                  <a
                    title={`Bar Cats commercial cleaning services ${item.name} page`}
                    href={item.link}
                  >
                    {item.name}
                  </a>
                </li>
              );
            })}
          </ul>
        </nav>
        <p className="header-phone">
          <a
            title={`Bar Cats commercial cleaning services phone number ${headerPhone}`}
            href={headerPhoneLink}
          >
            {headerPhone}
          </a>
        </p>
      </div>
    </header>
  );
};

export default Header;
