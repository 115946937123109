import React, { useState } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import firebase from "../../config/firebaseConfig";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import { CircularProgress } from "@mui/material";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";

const functions = getFunctions();
const sendEmail = httpsCallable(functions, "sendEmail");

const Contact = (props) => {
  const { contact, social } = props;
  const [error, setError] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);
  const [contactForm, setContactForm] = useState({
    name: {
      value: "",
      elementConfig: {
        required: true,
        id: "standard-name",
        label: "Your Name",
      },
      validation: {
        required: true,
        errorMessage: "Please, enter your name",
      },
      valid: false,
      blur: false,
    },

    email: {
      value: "",
      elementConfig: {
        required: true,
        id: "standard-email",
        label: "Your Email",
      },
      validation: {
        required: true,
        isEmail: true,
        errorMessage: "Please, enter a valid email address",
      },
      valid: false,
      blur: false,
    },
    subject: {
      value: "",
      elementConfig: {
        required: true,
        id: "standard-subject",
        label: "Subject",
      },
      validation: {
        required: true,
        errorMessage: "Please, enter a subject",
      },
      valid: false,
      blur: false,
    },
    message: {
      value: "",
      elementConfig: {
        required: true,
        id: "standard-multiline-static",
        label: "Your Message",
        multiline: true,
        minRows: 4,
      },
      validation: {
        required: true,
        errorMessage: "Please, enter your message",
      },
      valid: false,
      blur: false,
    },
    honeypot: {
      value: "",
      elementConfig: {
        className: "honeypot",
        label: "If you are a human, do not type anything here.",
      },
      validation: {},
      valid: true,
      blur: false,
    },
  });

  const formElementsArray = [];
  for (let key in contactForm) {
    formElementsArray.push({
      id: key,
      ...contactForm[key],
    });
  }

  const formElements = formElementsArray.map((element) => {
    return (
      <Grid key={element.id} item xs={12} md={12} container spacing={2}>
        <TextField
          {...element.elementConfig}
          error={!element.valid && element.blur}
          helperText={
            !element.valid && element.blur
              ? element.validation.errorMessage
              : null
          }
          onChange={(event) => inputChangedHandler(event, element.id)}
          onBlur={(event) => inputChangedHandler(event, element.id)}
          value={element.value}
          fullWidth
          margin="normal"
        ></TextField>
      </Grid>
    );
  });

  const inputChangedHandler = (event, inputIdentifier) => {
    const updatedFormElement = {
      ...contactForm[inputIdentifier],
      value: event.target.value,
      valid: checkValidity(
        event.target.value,
        contactForm[inputIdentifier].validation
      ),
      blur: event.type === "blur" ? true : false,
      touched: true,
    };

    const updatedContactForm = {
      ...contactForm,
      [inputIdentifier]: updatedFormElement,
    };
    let formIsValid = true;
    for (let inputElementIdentifier in updatedContactForm) {
      formIsValid =
        updatedContactForm[inputElementIdentifier].valid && formIsValid;
    }

    setContactForm(updatedContactForm);

    setFormIsValid(formIsValid);
  };

  const checkValidity = (value, rules) => {
    let isValid = true;
    if (rules.required) {
      isValid = value.trim() !== "" && isValid;
    }

    if (rules.isEmail) {
      const pattern =
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

      //`pattern.test()` returns true or false
      isValid = pattern.test(value) && isValid;
    }

    return isValid;
  };

  const closeSnackbar = () => {
    setOpenSnackbar(false);
  };

  const submitForm = () => {
    if (contactForm.honeypot.value !== "") {
      return;
    }
    setIsLoading(true);

    sendEmail({
      name: contactForm.name.value,
      email: contactForm.email.value,
      subject: contactForm.subject.value,
      message: contactForm.message.value,
    })
      .then(() => {
        let originalContactForm = {};
        for (let key in contactForm) {
          originalContactForm[key] = {
            ...contactForm[key],
            value: "",
            valid: key === "honeypot" ? true : false,
            blur: false,
          };
        }

        setContactForm(originalContactForm);
        setFormIsValid(false);
        setError(false);
        setIsLoading(false);
        setOpenSnackbar(true);
      })
      .catch(() => {
        setError(true);
        setIsLoading(false);
        setOpenSnackbar(true);
      });
  };

  return (
    <section id="contact" className="contact" data-aos="fade-up">
      <div className="container">
        <div className="section-title">
          <h2>{contact.title}</h2>
        </div>

        <div className="row no-gutters justify-content-center">
          <div className="col-lg-5 d-flex align-items-stretch">
            <div className="info">
              {contact.contactInfo.map((item, index) => {
                return (
                  <div key={index} className="contactblock mt-4">
                    <i className={item.icon}></i>
                    <p className="itemTypeTitle">{item.type}:</p>
                    <p>
                      <a
                        href={item.linkto}
                        title={`Bar Cats commercial cleaning services - ${item.value}`}
                      >
                        {item.value}
                      </a>
                    </p>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="social-media col-lg-5 d-flex justify-content-center align-items-stretch">
            <p className="socialfollow">
              <strong>{social.title}</strong>
              <br />
              <a
                href={social.link}
                target="_blank"
                rel="noopener noreferrer"
                title="Follow Bar Cats commercial cleaning services on facebook"
              >
                <i title={social.alt} className="icofont-facebook"></i>
              </a>
              <br />
              {social.text}
            </p>
          </div>
        </div>

        <div className="row mt-5 justify-content-center">
          <div className="col-lg-10">
            <Grid item md={12} container alignItems="center" direction="column">
              <h2>Send us a message</h2>
              <form className="contact-form">
                {formElements}
                <Grid container justifyContent="center">
                  <Box mt="2rem">
                    {isLoading ? (
                      <CircularProgress />
                    ) : (
                      <Button
                        onClick={submitForm}
                        title="Barctas commercial cleaning services submit message"
                        disabled={!formIsValid}
                        variant="contained"
                        color="primary"
                      >
                        Send Message
                      </Button>
                    )}
                  </Box>
                </Grid>
              </form>
            </Grid>
          </div>
        </div>
      </div>
      {error ? (
        <Snackbar
          open={openSnackbar}
          onClose={closeSnackbar}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert onClose={closeSnackbar} severity="error">
            Oops! Something went wrong, try again later.
          </Alert>
        </Snackbar>
      ) : (
        <Snackbar
          open={openSnackbar}
          autoHideDuration={2000}
          onClose={closeSnackbar}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert severity="success">Message sent!</Alert>
        </Snackbar>
      )}
    </section>
  );
};

export default Contact;
