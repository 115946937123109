import React from "react";

const About = (props) => {
  return (
    <section id="about" className="about" data-aos="fade-up">
      <div className="container-fluid m-0 p-0">
        <div className="jumbotron paral paralsec1">
          <h3>WHO WE ARE</h3>
          <div className="d-flex justify-content-center">
            <div className="col-md-6">
              <p className="lead">
                Bar Cats is a Commercial Cleaning Company specializing in bars
                and restaurants in the core of downtown Toronto.
              </p>
              <p>
                We are a fully insured and bonded company, available:
                <br />
                24 hours a day, 7 days a week, 365 days a year.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
