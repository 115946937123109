import React from "react";
import parse from "html-react-parser";

const Services = (props) => {
  const { serviceDescription, icon, marquee } = props;
  return (
    <section id="services" className="services" data-aos="fade-up">
      <div className="container">
        <div className="section-title">
          <h2>Services</h2>
          <h3 className="marquee">{parse(marquee)}</h3>
        </div>

        <div className="row">
          {icon.map((item, index) => {
            return (
              <div
                key={index}
                className="col-md-12 col-lg-12 d-flex align-items-stretch mb-5
              mb-lg-0"
              >
                <div className="icon-box icon-box-blue">
                  <div className="icon-container">
                    <div className="icon">
                      <i className={item.iconname}></i>
                    </div>
                    <h4 className="title">{parse(item.title)}</h4>
                  </div>
                  <p className="description">{parse(item.description)}</p>
                </div>
              </div>
            );
          })}

          <p className="description text-center full-width">
            {parse(serviceDescription)}
          </p>
        </div>
      </div>
    </section>
  );
};

export default Services;
