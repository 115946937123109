import React, { Fragment } from "react";

import { CONTENT } from "./data/content";
import Header from "./components/header";
import Footer from "./components/footer";
import Hero from "./components/hero";
import About from "./components/about";
import Services from "./components/services";
import Contact from "./components/contact";
import { HelmetProvider } from "react-helmet-async";
function App() {
  const content = CONTENT;

  return (
    <HelmetProvider>
      <Fragment>
        <Header
          siteName={content.siteName}
          siteNameTitle={content.siteNameTitle}
          headerPhone={content.headerPhone}
          headerPhoneLink={content.headerPhoneLink}
        />
        <main id="main">
          <Hero />
          <Services
            serviceDescription={content.services.serviceDescription}
            icon={content.services.icon}
            marquee={content.services.marquee}
          />
          <About
            title={content.about.title}
            contentlead={content.about.contentlead}
            content={content.about.content}
          />
          <Contact contact={content.contact} social={content.social} />
        </main>
        <Footer />
      </Fragment>
    </HelmetProvider>
  );
}

export default App;
