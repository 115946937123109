import React from "react";
import { Helmet } from "react-helmet-async";

const Footer = (props) => {
  return (
    <>
      <footer id="footer" className="text-center d-flex justify-content-center">
        <p>
          &copy; <span id="copyright"></span> BAR CATS COMMERCIAL CLEANING
          SERVICES. All rights reserved.
        </p>
      </footer>
    </>
  );
};

export default Footer;
