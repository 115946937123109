// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAxpmVj8ZXVRcr5dCfnfTJLNrUhBpA-75k",
  authDomain: "barcatscleaning.firebaseapp.com",
  projectId: "barcatscleaning",
  storageBucket: "barcatscleaning.appspot.com",
  messagingSenderId: "350733304017",
  appId: "1:350733304017:web:d4c58173433996facc45f0",
};

//initialize a Firebase instance
firebase.initializeApp(firebaseConfig);
//initialize Cloud Functions through Firebase
//this is optional, in case you want you wanna use analytics
export default firebase;
