import React from "react";

const Hero = (props) => {
  return (
    <section
      id="hero"
      className="d-flex flex-column justify-content-center align-items-center"
    >
      <div className="container text-center text-md-left">
        <div className="col-lg-6">
          <img
            className="full-width"
            src="/assets/images/barcats-cleaning-service.webp"
            ariaLabel="BAR CATS COMMERCIAL CLEANING SERVICES"
            alt="BAR CATS COMMERCIAL CLEANING SERVICES"
            width="auto"
            height="auto"
            title="BAR CATS COMMERCIAL CLEANING SERVICES"
            loading="eager"
          />
        </div>
      </div>
    </section>
  );
};

export default Hero;
